/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import '~bootstrap/dist/css/bootstrap';
// General theme contents
@import 'partials/variables';
@import 'partials/general';
@import 'partials/generic';
@import 'partials/mixins/function';

// important Element
@import 'partials/menu/menu-lite';
@import 'partials/widget/widget';

// Theme Element
@import 'partials/theme-elements/form';
@import 'partials/theme-elements/radiobox-checkbox';
@import 'partials/theme-elements/labels-badges';
@import 'partials/theme-elements/data-tables';
@import 'partials/theme-elements/authentication';
@import 'partials/theme-elements/button';
@import 'partials/theme-elements/alert';
@import 'partials/theme-elements/breadcrumb-pagination';
@import 'partials/theme-elements/progress';
@import 'partials/theme-elements/tooltip';
@import 'partials/theme-elements/popover';
@import 'partials/theme-elements/nav';
@import 'partials/theme-elements/modal';
@import 'partials/theme-elements/notes';
@import 'partials/theme-elements/gallery-masonry';
@import 'partials/theme-elements/gallery-advance';

@import '~fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';

// Other
@import 'partials/other/chat';
@import 'partials/other/prism';
@import 'partials/other/toolbar';
// @import "partials/other/Switches";
@import 'partials/other/wizard';
@import 'partials/other/chart';
@import 'partials/other/icon-lauouts';
@import 'partials/other/calendar';
@import 'partials/other/file-upload';

@import 'partials/layouts/dark';
@import 'partials/layouts/rtl';

@import 'partials/third-party/third-party';

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.swal2-container {
  z-index: 1300;
}

.pac-target-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin-top: 18px;
  border-bottom: 1px solid #5352529e;
  padding: 10px;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }
  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3f51b5;
    pointer-events: none;
  }
}

@font-face {
  font-family: "micr";
  src: local("micr"), url("../micr-encoding/micrenc.ttf") format("truetype");
}

.pac-container {
  z-index: 9999 !important;
}

.select__menu {
  z-index: 9999 !important;
}
// .radio input[type=radio] + .form-check-label:after, .radio input[type=radio] + .cr:after{
//     top: 15px;
// }

.lightbox-wrapper {
  z-index: 9999;
}

.imageAdd {
  color: #263238;
  width: initial;
  height: 100px;
  opacity: 1;
  z-index: 5;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  box-sizing: border-box;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 4px;

  .blockContainer {
    z-index: 10;
    position: relative;
    text-align: center;

  }
  .positionedBtn {
    top: -8px;
    right: -8px;
    width: 40px;
    height: 40px;
    opacity: 0;
    position: absolute;
    transition: .5s ease;
  }

  &:hover {
    top: -8px;
    right: -8px;
    width: 40px;
    height: 40px;
    opacity: 0;
    position: absolute;
    transition: .5s ease;
  }
}



.image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.image-wrapper {
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  color: #263238;
  width: initial;
  height: 100px;
  opacity: 1;
  z-index: 5;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
  box-sizing: border-box;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 4px;
}

.delete-button {
  top: -8px;
  right: -8px;
  width: 40px;
  height: 40px;
  opacity: 0;
  z-index: 10;
  position: absolute;
  transition: opacity 0.3s ease; /* Додана транзиція для плавної зміни прозорості */
}

.image-wrapper:hover .delete-button {
  opacity: 1;
}

.custom-dropzone {
  min-height: 0;
  margin: 0 0 10px 0;
}

.MuiTablePagination-selectLabel {
  margin: 0
}

.MuiTablePagination-displayedRows {
  margin: 0
}


